import React from 'react';
import logo from '../img/logo-removebgfinal.png';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

function Header() {
  return (
    <div>
      <header style={{ background: '#3e3e3e', height: '120px', width: '100%' }}>
        <Box>
          <div class="loop-wrapper" style={{ overflow: 'hidden' }}>
            <div class="mountain"></div>
            <div class="hill"></div>
            <div class="tree"></div>
            <div class="tree"></div>
            <div class="tree"></div>
            <div class="rock"></div>
            <div class="truck">
              <img
                src={logo}
                alt="Kal"
                style={{
                  position: 'absolute',
                  height: '50px',
                  width: '52px',
                }}
              />
            </div>
            <div class="wheels"></div>
          </div>
          <Box
            style={{
              position: 'absolute',
              top: '3%',
              bottom: '3%',
              left: '15%',
              height: '80px',
              width: '250px',
              backgroundColor: '',
            }}
          >
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                style={{ position: 'absolute', height: '80px' }}
              />
            </Link>
          </Box>

          <div class="callUs">
            <IconButton
              style={{
                position: 'absolute ',
                top: '30px',
                left: '60%',
                height: '80px',
                color: 'white',
              }}
            >
              <PhoneIcon />
              <div>
                <label style={{ color: '#139EA0' }}>call Us:</label>
                <br />
                <label style={{ fontStyle: 'italic', fontSize: '18px' }}>
                  (+251) 91 138 1262
                </label>
              </div>
            </IconButton>
          </div>

          <div class="email">
            <IconButton
              style={{
                position: 'absolute ',
                top: '30px',
                left: '80%',
                height: '80px',
                color: 'white',
              }}
            >
              <EmailIcon />
              <div>
                <label style={{ color: '#139EA0' }}>Email:</label>
                <br />
                <label style={{ fontStyle: 'italic', fontSize: '18px' }}>
                  tsegaye.kalogistic@gmail.com
                </label>
              </div>
            </IconButton>
          </div>
        </Box>
      </header>
    </div>
  );
}

export default Header;
