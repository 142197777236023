import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pic20 from '../img/inner-banner.jpg'
import civil from '../img/civil.png'


import Pic29 from '../img/fact-counter-bg.jpg'
import AccountCircle from '@material-ui/icons/AccountCircle'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LanguageIcon from '@material-ui/icons/Language'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import 'bootstrap/dist/css/bootstrap.css'

import Header from './header'
import Footer from './footer'
import HeaderTwo from './headerTwo'

import pic1 from '../img/img_new/1.png'
import pic2 from '../img/img_new/2.png'
import pic5 from '../img/img_new/5.png'
import pic6 from '../img/img_new/6.png'
import pic7 from '../img/img_new/7.png'
import pic8 from '../img/img_new/18.png'
import pic9 from '../img/img_new/9.png'
import pic10 from '../img/img_new/10.png'

import pic11 from '../img/img_new/11.png'
import pic12 from '../img/img_new/12.png'
import pic13 from '../img/img_new/13.png'
import pic14 from '../img/img_new/14.png'
import pic15 from '../img/img_new/15.png'
import pic16 from '../img/img_new/16.png'
import pic17 from '../img/img_new/17.png'
import pic18 from '../img/img_new/18.png'
import pic19 from '../img/img_new/19.png'
import pic20 from '../img/img_new/20.png'

import pic21 from '../img/img_new/21.png'
import pic22 from '../img/img_new/22.png'
import pic23 from '../img/img_new/50.png'
import pic24 from '../img/img_new/24.png'
import pic25 from '../img/img_new/55.png'
import pic26 from '../img/img_new/26.png'
import pic27 from '../img/img_new/27.png'
import pic28 from '../img/img_new/50.png'
import pic29 from '../img/img_new/29.png'
import pic30 from '../img/img_new/30.png'
import pic31 from '../img/img_new/31.png'
import pic32 from '../img/img_new/32.png'
import pic33 from '../img/img_new/33.png'
import pic34 from '../img/img_new/34.png'
import pic35 from '../img/img_new/51.png'
import pic36 from '../img/img_new/47.png'
import pic37 from '../img/img_new/45.png'
import pic38 from '../img/img_new/38.png'
import pic39 from '../img/img_new/39.png'
import pic40 from '../img/img_new/40.png'
import pic41 from '../img/img_new/41.png'
import pic42 from '../img/img_new/42.png'
import pic43 from '../img/img_new/43.png'
import pic44 from '../img/img_new/44.png'
import pic51 from '../img/img_new/51.png'
import pic61 from '../img/img_new/81.png'
import pic62 from '../img/img_new/72.png'
import pic63 from '../img/img_new/63.png'
import pic64 from '../img/img_new/64.png'
import pic65 from '../img/img_new/58.png'
import pic66 from '../img/img_new/66.png'
import pic67 from '../img/img_new/79.png'
import pic68 from '../img/img_new/68.png'
import pic69 from '../img/img_new/69.png'
import pic70 from '../img/img_new/70.png'



const Gallery = () => {
  return (
    <div>
      <Header />
      <HeaderTwo />
      <Container fluid style={{}}>
        <Row
          style={{
            backgroundImage: `url(${Pic20})`,
            backgroundPosition: '-400px -300px',
            height: '230px',
            color: 'white',
          }}
        >
          <h1
            style={{
              fontWeight: 'bold',
              marginLeft: '20%',
              marginTop: '80px',
            }}
          >
            Gallery
          </h1>
        </Row>
        <Row
          style={{
            marginLeft: '4%',
            padding: '5%',
          }}
        >
          <h3
            style={{
              fontWeight: 'bold',
            }}
          >
            Our Gallery
          </h3>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '3%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic1} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                helping the community
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic2} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Discussion
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic13} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Waste Management
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic14} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Kal Logistics
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic5} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Kal Logistics
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic6} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Kal Logistics{' '}
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '3%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic7} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Office
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic8} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Kal Logistics{' '}
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic9} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Kal Logistics{' '}
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic10} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                AT Night
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic11} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Transportation
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic12} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Transportation
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic15} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Office
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic16} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Office
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic17} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Office
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic18} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Kal Logistics
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic19} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Logistics Supply
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic20} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Fuel Procurement And Delivery
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic21} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Bulk fuel supplies
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic22} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Advisory Team for Health, Safety And Environment (HSE)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic23} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                take care of Nature
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic24} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Group
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic25} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Civil Works
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic26} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Site
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic27} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Discussion
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic28} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Advisory Team for Health, Safety And Environment (HSE)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic29} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Bulk fuel supplies
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic30} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                On The Road
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic31} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Site
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic32} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Group
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic33} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Work
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic34} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Advisory Team for Health, Safety And Environment (HSE)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic35} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Civil Work
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic36} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                At Office
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic37} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Civil Works
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic38} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Civil Works
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic39} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                machinaries
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic40} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Support Peoples
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic41} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Support Peoples
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic42} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Support Peoples
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic43} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Support Peoples
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic44} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Support Peoples
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '3%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={civil} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                General Construction and Civil Works
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic61} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Remote Catering (Camp Catering service and food supply)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic62} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Camp Mobilization, Recruitment and Management
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic63} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Waste Management
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic64} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Providing skilled, semi -skilled and unskilled labour and
                Management
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic65} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Transportation of Liquid and Dry Commodities
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic66} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Bulk fuel supplies
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic67} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Advisory Team for Health, Safety And Environment (HSE)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic68} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Supply of Personal Protective Equipment (PPE)
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={pic69} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Purchase and Delivery of Camping Item
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic70} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Logistics Supply and Planning Management
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={pic51} alt='containerPic' />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Fuel Procurement And Delivery For Project Sites
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row
          className='viewabcd'
          style={{
            minHeight: '275px',
            backgroundAttachment: 'fixed',
            backgroundImage: `url(${Pic29})`,
            backgroundPosition: '-400px -300px',
            fontStyle: 'italic',
            textAlign: 'center',
          }}
        >
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <AccountCircle
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Emploies in Team</p>
          </Col>
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <LocalShippingIcon
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Company Vihicles</p>
          </Col>
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <LanguageIcon
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Worldwide Clients</p>
          </Col>
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <CheckCircleIcon
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Projects Done</p>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  )
}

export default Gallery
