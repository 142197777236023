import React from 'react';
import pic1 from '../img/pic1.png';
import pic2 from '../img/pic2.png';
import pic3 from '../img/pic3.png';
import pic4 from '../img/pic4.png';
import pic5 from '../img/pic5.png';
import picnew from '../img/picnew.png';
import pic9 from '../img/pic9.png';
import pic10 from '../img/pic10.png';
import pic11 from '../img/pic11.png';
import pic12 from '../img/pic12.jpg';
import pic13 from '../img/tsegsh.png';
import pic14 from '../img/meron.png';
import pic15 from '../img/Adane.png';
import pic16 from '../img/NARDOS.png';
import plain from '../img/plain.png';
import sheep from '../img/sheep.png';
import vaicle from '../img/vehcle.png';
import mans from '../img/mans.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import PublicTwoToneIcon from '@material-ui/icons/PublicTwoTone';
import BookmarksTwoToneIcon from '@material-ui/icons/BookmarksTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import SportsKabaddiTwoToneIcon from '@material-ui/icons/SportsKabaddiTwoTone';

import Header from './header';
import Footer from './footer';
import HeaderTwo from './headerTwo';

const AutoplaySlider = withAutoplay(AwesomeSlider);
const src1 = 'video.gif';
const Slider = () => (
  <AutoplaySlider
    play={true}
    animation="cubeAnimation"
    interval={3000}
    bullets={false}
    style={{}}
  >
    <div data-src={pic1} />
    <div data-src={pic2} />
    <div data-src={pic3} />
    <div data-src={pic4} />
    <div data-src={pic5} />
    <div data-src={picnew} />
  </AutoplaySlider>
);

function Homepage() {
  return (
    <div>
      <Header />
      <HeaderTwo />

      <Slider />
      <Container fluid>
        <Row style={{ marginTop: '3%', marginLeft: '5%', marginRight: '5%' }}>
          <Col
            style={{ overflow: 'hidden', backgroundColor: '' }}
            xs={12}
            md={4}
          >
            <img style={{}} src={pic10} alt="balloons"></img>
          </Col>
          <Col
            xs={12}
            md={8}
            style={{
              backgroundColor: '',
            }}
          >
            <h3
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                marginTop: '3%',
              }}
            >
              Who We Are
            </h3>
            <p style={{ color: '#898989' }}>
              Kal Construction and Logistic was found in 2015 by Mr Tsegaye with
              a work philosophy well stopped, accuracy and transparency, optimum
              quality of work, efficiency and impeccable services. Kal
              Construction and logistic being a winning formula, the company has
              been innovating by acquiring new tools and developing an attitude
              of openness to new ideas and putting more time to succeed in the
              industry that the company has involved in. We work with different
              sector in the business trade. Commercial sector, NGO’s, Oil and
              Gas explosion companies, Mining, Security and Logistics few of our
              customers. Over its few years in the market Kal Construction and
              Logistic has acquired a solid reputation with high level of
              excellence achieved in the implementation of different project.
              The completion on time and according to client budget remains at
              the heart of the company priorities.
            </p>
            <Row>
              {' '}
              <Col>
                <ul style={{ color: '#4486BD' }}>
                  <li style={{ float: 'left' }}>
                    <p>
                      <b>Project safety</b> is our priority.
                    </p>
                  </li>
                  <br></br>
                  <br></br>
                  <li style={{ float: 'left' }}>
                    {' '}
                    <p>
                      <b>Customer cantered</b> Our success depends on our
                      customer success.
                    </p>
                  </li>
                  <br></br>
                  <br></br>
                  <li style={{ float: 'left' }}>
                    {' '}
                    <p>
                      <b>Integrity</b> We will treat others with respect.
                    </p>
                  </li>
                  <br></br>
                  <br></br>
                  <li style={{ float: 'left' }}>
                    {' '}
                    <p>
                      <b>Expectations </b>We will seek to exceed the
                      Expectations of others.
                    </p>
                  </li>
                  <br></br>
                  <br></br>
                  <li style={{ float: 'left' }}>
                    {' '}
                    <p>
                      <b>Quality </b> We will deliver quality without
                      compromise.
                    </p>
                  </li>
                  <br></br>
                  <br></br>
                </ul>
              </Col>
              <Col>
                {
                  <div
                    class="containerPic"
                    style={{
                      marginTop: '10%',
                      borderRadius: '50%',
                    }}
                  >
                    <img style={{}} src={src1} alt="containerPic"></img>
                  </div>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div
        style={{
          backgroundColor: '#e3e8ec',
        }}
      >
        <Container float>
          <Row style={{}}>
            <Col style={{ marginTop: '3%' }} xs={12} md={12}>
              <h3 style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>
                Our Team
              </h3>
              <p style={{ color: '#898989' }}>
                An experienced and dynamic team surround the founder for all
                operations. The team is guided by our core values. Our
                commitment to outstanding job completion, exceptional customer
                service and super safety performance has made Kal a partner's
                choice in the industry. Our team hails from every craft and
                expertise in the field allowing us to combine innovation methods
                and accountable project management to get the job done and get
                it done right. The company relies heavily on the training and
                involvement of employees at all levels.
              </p>
            </Col>
          </Row>

          <Row style={{ backgroundColor: 'white', marginTop: '3%' }}>
            <Col
              xs={12}
              md={6}
              style={{
                columnCount: 2,
                backgroundColor: ' white ',
                marginTop: '3%',
              }}
            >
              <Col style={{ overflow: 'hidden' }}>
                <>
                  <img
                    style={{ marginTop: '5%' }}
                    src={plain}
                    alt="balloons"
                  ></img>
                </>
              </Col>
              <Col>
                <div
                  style={{
                    marginTop: '3%',
                    overflow: 'hidden',
                  }}
                >
                  <h5
                    style={{
                      marginTop: '5%',
                      fontWeight: 'bold',
                      fontFamily: 'monospace',
                    }}
                  >
                    AIR FRIGHT
                  </h5>

                  <p style={{ color: '#898989' }}>
                    Air cargo is any property carried or to be carried in an
                    aircraft. Air cargo comprises air freight, air express.
                  </p>
                </div>
              </Col>
            </Col>
            <Col
              xs={12}
              md={6}
              style={{
                columnCount: 2,
                marginTop: '3%',
                backgroundImage:
                  'linear-gradient(90deg, #a2a2a2  50%, #e1e0df 50%)',
              }}
            >
              <Col style={{ overflow: 'hidden' }}>
                <>
                  <img
                    style={{ marginTop: '5%' }}
                    src={sheep}
                    alt="balloons"
                  ></img>
                </>
              </Col>
              <Col>
                <div style={{ marginTop: '3%', overflow: 'hidden' }}>
                  <h5
                    style={{
                      marginTop: '5%',
                      fontWeight: 'bold',
                      fontFamily: 'monospace',
                    }}
                  >
                    OCEAN FRIGHT
                  </h5>

                  <p style={{ color: '#898989' }}>
                    Ship transport can be over any distance by boat, ship,
                    sailboat, over oceans, through canals or along rivers.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: 'white',
              backgroundImage:
                'linear-gradient(-45deg, #a2a2a2  40%, #e1e0df 50%)',
            }}
          >
            <Col
              xs={12}
              md={6}
              style={{
                columnCount: 2,
                backgroundColor: 'white',
                marginTop: '3%',
              }}
            >
              <Col style={{ overflow: 'hidden' }}>
                <>
                  <img
                    style={{ marginTop: '5%' }}
                    src={vaicle}
                    alt="balloons"
                  ></img>
                </>
              </Col>
              <Col>
                <div style={{ marginTop: '3%', overflow: 'hidden' }}>
                  <h5
                    style={{
                      marginTop: '5%',
                      fontWeight: 'bold',
                      fontFamily: 'monospace',
                    }}
                  >
                    ROAD FRIGHT
                  </h5>

                  <p style={{ color: '#898989' }}>
                    The first methods of road transport were horses, oxen or
                    even humans carrying goods.
                  </p>
                </div>
              </Col>
            </Col>
            <Col
              xs={12}
              md={6}
              style={{
                columnCount: 2,
                marginTop: '3%',
                backgroundImage:
                  'linear-gradient(90deg, #a2a2a2  50%, #e1e0df 50%)',
              }}
            >
              <Col style={{ overflow: 'hidden' }}>
                <>
                  <img
                    style={{ marginTop: '5%' }}
                    src={mans}
                    alt="balloons"
                  ></img>
                </>
              </Col>
              <Col>
                <div style={{ marginTop: '3%', overflow: 'hidden' }}>
                  <h5
                    style={{
                      marginTop: '5%',
                      fontWeight: 'bold',
                      fontFamily: 'monospace',
                    }}
                  >
                    WAREHOUSES
                  </h5>

                  <p style={{ color: '#898989' }}>
                    A warehouse is a commercial building for storage of goods.
                    Warehouses are used by importers, exporters etc.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        style={{
          backgroundColor: '#3e3e3e',
        }}
      >
        <Container float>
          <Row style={{ marginTop: '3%' }}>
            <Col
              xs={12}
              md={4}
              style={{
                overflow: 'hidden',
                borderSpacing: '5%',
                textAlign: 'center',
              }}
            >
              <img
                style={{ marginTop: '15%' }}
                src={pic9}
                alt="manWithComunicator"
              ></img>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                color: 'white',
                marginTop: '3%',
                textAlign: 'center',
                marginBottom: '5%',
              }}
            >
              <BookmarksTwoToneIcon
                style={{
                  marginTop: '3%',
                  color: 'white',
                  fontSize: '50px',
                }}
              />
              <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Contract logistics
              </p>
              <p style={{ color: '#ABC3D8' }}>
                Wе оffеr dіffеrеnt ѕеrvісеs rаngіng frоm logistics, warehousing,
                cargo, transport and other related services.
              </p>
              <PublicTwoToneIcon
                style={{ marginTop: '3%', color: 'white', fontSize: '50px' }}
              />
              <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Reach Anywhere
              </p>
              <p style={{ color: '#ABC3D8' }}>
                Air cargo is any property carried or to be carried in an
                aircraft. Air cargo comprises air freight, air express.
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                color: 'white',
                marginTop: '3%',
                textAlign: 'center',
                marginBottom: '5%',
              }}
            >
              <SportsKabaddiTwoToneIcon
                style={{ marginTop: '3%', color: 'white', fontSize: '50px' }}
              />
              <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Consulting Services
              </p>
              <p style={{ color: '#ABC3D8' }}>
                At Kal Logistics, we аrе making research continuously аnd
                improving оur ѕеrvісеѕ to thе hіghеѕt ѕtаndаrdѕ.
              </p>
              <LocalShippingTwoToneIcon
                style={{ marginTop: '3%', color: 'white', fontSize: '50px' }}
              />

              <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Goods Tracking
              </p>
              <p style={{ color: '#ABC3D8' }}>
                We provide you instant updates of the progress of the
                transportation of goods. This is only a sample text.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row style={{ marginTop: '3%' }}>
          <Col xs={12} md={8} style={{}}>
            <h3
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                marginTop: '3%',
                marginBottom: '3%',
              }}
            >
              SOME OF OUR CORE VALUES
            </h3>
            <h5 style={{ fontWeight: 'bold' }}>MISSION</h5>
            <p style={{ color: '#898989' }}>
              Providing the best logistic and construction experience through
              Relationship built on integrity Success built on performance
            </p>
            <h5 style={{ fontWeight: 'bold' }}>VISION</h5>
            <p style={{ color: '#898989' }}>
              To be sought out as the recognized and trusted firm in the
              industry and community
            </p>
            <h5 style={{ fontWeight: 'bold' }}>COMPANY PHILOSOPHY</h5>
            <p style={{ color: '#898989' }}>
              In our corporate growth, we took time to develop guiding
              principles to ensure we would fulfil our vision and mission every
              single day.
            </p>
          </Col>
          <Col
            xs={12}
            md={4}
            style={{
              borderSpacing: '5%',
              textAlign: 'center',
            }}
          >
            <h3
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                marginTop: '3%',
                marginBottom: '3%',
              }}
            >
              OUR OFFICE
            </h3>
            <img
              style={{ marginTop: '5%', marginBottom: '5%' }}
              src={pic11}
              alt="balloons"
            ></img>
            <p style={{ color: '#898989' }}>
              We can transport your goods around the world. Call us for your
              need today.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: '#3e3e3e',
          marginTop: '3%',
        }}
      >
        <Row style={{ color: 'white' }}>
          <Col
            style={{ color: 'white', marginTop: '3%', marginLeft: '7%' }}
            xs={12}
            md={5}
          >
            <h3
              style={{
                color: 'white',
                marginTop: '3%',
                marginButtom: '3%',
                fontWeight: 'bold',
              }}
            >
              Transportation of Liquid and Dry Commodities
            </h3>
            <p style={{ color: '#898989', marginTop: '3%' }}>
              Within this ever-demanding business world, customers must really
              on innovative transportation solutions to help them remain
              competitive Kal Logistic and Trading has been helping its
              customers meet and exceed their transportation needs by providing
              exceptional service, equipment and personnel. Within customer
              built equipment specifically designed and tailored for your
              sensitive and industry jobs, it is no wonder we are setting
              standard in today’s transportation world
            </p>
          </Col>
          <Col
            style={{
              color: 'white',
              marginTop: '3%',
              marginBottom: '3%',
              marginLeft: '6%',
            }}
            xs={12}
            md={5}
          >
            <h3
              style={{
                color: 'white',
                marginTop: '3%',
                marginButtom: '3%',
                fontWeight: 'bold',
              }}
            >
              Advisory Team for Health, Safety and Environment (HSE)
            </h3>
            <p style={{ color: '#898989', marginTop: '3%' }}>
              Our priority is to protect the health and safety of our people and
              public to maintain the environment impact associated with
              activities. While providing the service we believe that the health
              and safety of the people and protection of the environment is one
              of the most important missions of Kal Logistics and Trading. Kal
              comply with laws and regulations ethically and set and maintain
              HSE management system to the highest level for the development of
              sustainable society with harmony between people and the
              environment.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row style={{ marginTop: '3%' }}>
          <Col xs={12} md={12}>
            {' '}
            <h3
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                marginTop: '3%',
                marginBottom: '3%',
              }}
            >
              OUR TEAM MEMBER
            </h3>
          </Col>
          <Col
            xs={12}
            md={3}
            style={{
              backgroundColor: '#F3F5F7',
              textAlign: 'center',
            }}
          >
            <img style={{ marginTop: '3%' }} src={pic13} alt="balloons"></img>
            <h4 style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Mr. Tsegaye Dejene
            </h4>
            <p style={{ color: '#898989', fontStyle: 'italic' }}>
              CEO(For Operation)
            </p>

            <p style={{ color: '#898989' }}>
              I take care of your goods reach their destination safely.
            </p>
          </Col>
          <Col
            xs={12}
            md={3}
            style={{
              backgroundColor: '#F3F5F7',
              textAlign: 'center',
            }}
          >
            <img style={{ marginTop: '3%' }} src={pic14} alt="balloons"></img>
            <h4 style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Mrs. Merone zeleke Tefera
            </h4>
            <p style={{ color: '#898989', fontStyle: 'italic' }}>
              Coorporate Manager
            </p>
            <p style={{ color: '#898989' }}>
              I take care of your goods reach their destination safely.
            </p>
          </Col>
          <Col
            xs={12}
            md={3}
            style={{
              backgroundColor: '#F3F5F7',
              textAlign: 'center',
            }}
          >
            <img style={{ marginTop: '3%' }} src={pic15} alt="balloons"></img>
            <h4 style={{ fontSize: '18px', fontWeight: 'bold' }}>
              MR. Adane Mengistu Demissie
            </h4>
            <p style={{ color: '#898989', fontStyle: 'italic' }}>
              Logistic and construction operations manger
            </p>
            <p style={{ color: '#898989' }}>
              I take care of your goods reach their destination safely.
            </p>
          </Col>
          <Col
            xs={12}
            md={3}
            style={{
              backgroundColor: '#F3F5F7',
              textAlign: 'center',
            }}
          >
            <img style={{ marginTop: '3%' }} src={pic16} alt="balloons"></img>
            <h4 style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Mrs. NARDOS BERIHU GEBREMDHIN
            </h4>
            <p style={{ color: '#898989', fontStyle: 'italic' }}>
              FINANCE AND ADMIN
            </p>
            <p style={{ color: '#898989' }}>
              I take care of your goods reach their destination safely.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundImage: `url(${pic12})`,
          backgroundPosition: '-400px -300px',
        }}
      >
        <Row style={{ background: 'rgba(0,0,0,0.5)', marginTop: '3%' }}>
          <Col
            style={{
              color: 'white',
              marginTop: '3%',
              marginBottom: '3%',
              marginLeft: '6%',
            }}
            xs={12}
            md={5}
          >
            <h3
              style={{
                marginTop: '3%',
                marginButtom: '3%',
                fontWeight: 'bold',
              }}
            >
              Supply of Personal Protective Equipment (PPE)
            </h3>
            <p style={{ marginTop: '3%' }}>
              Your personal safety is our business and greatest concern, PPE is
              essential for the safety and protection of all industries on your
              site. Kal Logistics and Trading is proud to be one of the provider
              companies for PPE on different sector. We know that our wide range
              of personnel protective equipment will be a superb quality and
              exceeds our customers’ expectations.
            </p>
          </Col>
          <Col
            style={{
              color: 'white',
              marginTop: '3%',
              marginBottom: '3%',
              marginLeft: '6%',
            }}
            xs={12}
            md={5}
          >
            <h3
              style={{
                marginTop: '3%',
                marginButtom: '3%',
                fontWeight: 'bold',
              }}
            >
              Giving back to the community
            </h3>
            <p style={{ marginTop: '3%' }}>
              For us, giving back to the community is a big part of our
              identity: its about who we are as a company and who we want to be.
              We are grateful to have such great community support and we are
              happy to give back and provide support for people who need it. We
              know that when communities are healthy, happy and productive
              everyone wins. We also know that we can’t sit around and expect
              other to solve problems so we do what we can, when we can.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Homepage;
