import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Pic20 from '../img/inner-banner.jpg'
import Pic21 from '../img/left-full-image.jpg'
import Pic22 from '../img/bg-1.jpg'
import Pic23 from '../img/1-3.png'

import Header from './header'
import Footer from './footer'
import HeaderTwo from './headerTwo'
import {  Link, } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  flexGrow: {
    flex: '1',
  },
  button: {
    backgroundColor: '#c22d0d',
    '&:hover': {
      backgroundColor: '#1FB5C7',
      color: 'white',
    },
  },
})

const AboutUs = () => {
  const classes = useStyles()
  return (
    <div>
      <Header />
      <HeaderTwo />
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            backgroundImage: `url(${Pic20})`,
            backgroundPosition: '-400px -300px',
            height: '230px',
            color: 'white',
          }}
        >
          <h1
            style={{
              fontWeight: 'bold',
              marginLeft: '20%',
              marginTop: '80px',
            }}
          >
            About Us
          </h1>
        </Row>
        <Row
          style={{
            padding: '5%',
            marginLeft: '6%',
            marginRight: '5%',
          }}
        >
          <h3
            style={{
              fontWeight: 'bold',
            }}
          >
            Who We Are
          </h3>
          <p>
            Kal Construction and Logistic was found in 2015 by Mr Tsegaye with a
            work philosophy well stopped, accuracy and transparency, optimum
            quality of work, efficiency and impeccable services. Kal
            Construction and logistic being a winning formula, the company has
            been innovating by acquiring new tools and developing an attitude of
            openness to new ideas and putting more time to succeed in the
            industry that the company has involved in. We work with different
            sector in the business trade. Commercial sector, NGO’s, Oil and Gas
            explosion companies, Mining, Security and Logistics few of our
            customers. Over its few years in the market Kal Construction and
            Logistic has acquired a solid reputation with high level of
            excellence achieved in the implementation of different project. The
            completion on time and according to client budget remains at the
            heart of the company priorities.
          </p>
        </Row>
      </Container>
      <Container style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            paddingLeft: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Transportation of Liquid and Dry Commodities
              </h5>
              <p>
                Within this ever-demanding business world, customers must really
                on innovative transportation solutions to help them remain
                competitive Kal Logistic and Trading has been helping its
                customers meet and exceed their transportation needs by
                providing exceptional service, equipment and personnel. Within
                customer built equipment specifically designed and tailored for
                your sensitive and industry jobs, it is no wonder we are setting
                standard in today’s transportation world.
              </p>
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Giving back to the community
              </h5>
              <p>
                For us, giving back to the community is a big pat of our
                identity: its about who we are as a company and who we want to
                be. We are grateful to have such great community support and we
                are happy to give back and provide support for people who need
                it. We know that when communities are healthy, happy and
                productive everyone wins. We also know that we can’t sit around
                and expect other to solve problems so we do what we can, when we
                can.
              </p>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Supply of Personal Protective Equipment (PPE)
              </h5>
              <p>
                Your personal safety is our business and greatest concern, PPE
                is essential for the safety and protection of all industries on
                your site. Kal Logistics and Trading is proud to be one of the
                provider companies for PPE on different sector. We know that our
                wide range of personnel protective equipment will be a superb
                quality and exceeds our customers’ expectations
              </p>
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Advisory Team for Health, Safety and Environment (HSE)
              </h5>
              <p>
                Our priority is to protect the health and safety of our people
                and public to maintain the environment impact associated with
                activities. While providing the service we believe that the
                health and safety of the people and protection of the
                environment is one of the most important missions of Kal
                Logistics and Trading. Kal comply with laws and regulations
                ethically and set and maintain HSE management system to the
                highest level for the development of sustainable society with
                harmony between people and the environment.
              </p>
            </Row>
          </Col>

          <Col md={4} sm={12}>
            <img src={Pic21} alt='containerPic' />
          </Col>
        </Row>
      </Container>

      <Row
        style={{
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '3%',
          backgroundImage: `url(${Pic22})`,
        }}
      >
        <Col
          md={6}
          sm={0}
          style={{
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Pic23} alt='planeAndCar' style={{ overflow: 'hidden' }} />
        </Col>
        <Col md={4} sm={12} style={{ overflow: 'hidden' }}>
          <h2 style={{ fontWeight: 'bold' }}>
            Reach your destination 100% sure and safe
          </h2>
          <p>
            Logiscargo іѕ a full ѕеrvісе logistics & cargo company, оur аіm іѕ
            tо gіvе the bеѕt to оur various clients аt аffоrаblе рrісе. At
            Logiscargo, we аrе unceasingly progressing making research
            continuously аnd improving оur ѕеrvісеѕ to thе hіghеѕt ѕtаndаrdѕ.{' '}
          </p>

          <Link to='/Services'>
            <Button
              className={classes.button}
              size='lg'
              style={{
                marginBottom: '3%',
                marginLeft: '3%',
              }}
              variant='warning'
            >
              VIEW OUR SERVICES
            </Button>
          </Link>
        </Col>
      </Row>
      {/* <Container fluid>
        <Row>
          <h3
            style={{
              fontWeight: 'bold',
              marginLeft: '10%',
              marginTop: '3%',
              marginBottom: '3%',
            }}
          >
            OUR CLIENTS
          </h3>
        </Row>
        <Row
          style={{
            fontWeight: 'bold',
            marginLeft: '5%',
            marginRight: '5%',
            marginBottom: '3%',
            overflow: 'hidden',
          }}
        >
          <Col md={2} sm={4} style={{ overflow: 'hidden' }}>
            <img
              src={cbe}
              alt="cbe"
              style={{ height: '100px', width: '100%' }}
            />
          </Col>
          <Col md={2} sm={4} style={{ overflow: 'hidden' }}>
            <img
              src={dangote}
              alt="dangote"
              style={{ height: '100px', width: '100%' }}
            />
          </Col>
          <Col md={2} sm={4} style={{ overflow: 'hidden' }}>
            <img
              src={dashine}
              alt="dashin"
              style={{ height: '100px', width: '100%' }}
            />
          </Col>
          <Col md={2} sm={4} style={{ overflow: 'hidden' }}>
            <img
              src={eal}
              alt="eal"
              style={{ height: '100px', width: '100%' }}
            />
          </Col>
          <Col md={2} sm={4} style={{ overflow: 'hidden' }}>
            <img
              src={hilton}
              alt="hilton"
              style={{ height: '100px', width: '100%' }}
            />
          </Col>
          <Col md={2} sm={4} style={{ overflow: 'hidden' }}>
            <img
              src={ride}
              alt="ride"
              style={{ height: '100px', width: '100%' }}
            />
          </Col>
        </Row>
      </Container> */}
      <Footer />
    </div>
  )
}

export default AboutUs
