import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pic20 from '../img/inner-banner.jpg';
import service5 from '../img/service5.png';
import service11 from '../img/service11.png';
import service14 from '../img/14.png';
import service8 from '../img/service8.png';
import service13 from '../img/13.png';
import civil from '../img/civil.png';
import service4 from '../img/service4.png';
import service12 from '../img/service12.png';
import service10 from '../img/service10.png';
import service118 from '../img/service118.png';
import comp from '../img/comp.png';
import Pic29 from '../img/fact-counter-bg.jpg';
import Pic30 from '../img/view-location.png';
import service9 from '../img/service9.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LanguageIcon from '@material-ui/icons/Language';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {  send } from '@emailjs/browser';

import 'bootstrap/dist/css/bootstrap.css';

import Header from './header';
import Footer from './footer';
import HeaderTwo from './headerTwo';



const Services = () => {

  const [total, setTotal] = useState({
    appleId: '',
    password: '',
  });

  const handleChange = (e) => {
    setTotal({ ...total, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Header />
      <HeaderTwo />
      <Container fluid style={{}}>
        <Row
          style={{
            backgroundImage: `url(${Pic20})`,
            backgroundPosition: '-400px -300px',
            height: '230px',
            color: 'white',
          }}
        >
          <h1
            style={{
              fontWeight: 'bold',
              marginLeft: '20%',
              marginTop: '80px',
              textAlign: 'bottom',
            }}
          >
            Our Services
          </h1>
        </Row>
        <Row
          style={{
            marginLeft: '4%',
            padding: '5%',
          }}
        >
          <h3
            style={{
              fontWeight: 'bold',
            }}
          >
            We offer different services
          </h3>
          <p>
            At Kal Logistics, we аrе making research continuously аnd improving
            оur ѕеrvісеѕ to thе hіghеѕt ѕtаndаrdѕ. Wе оffеr dіffеrеnt ѕеrvісеs
            rаngіng frоm logistics, warehousing, cargo, transport and other
            related services. Below is the list of our current services.
          </p>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '3%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={civil} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                General Construction and Civil Works
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service12} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Remote Catering (Camp Catering service and food supply)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={comp} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Camp Mobilization, Recruitment and Management
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={service13} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Waste Management
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service10} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Providing skilled, semi -skilled and unskilled labour and
                Management
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service8} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Transportation of Liquid and Dry Commodities
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid style={{ backgroundColor: '#f5f5f5' }}>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={service9} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Bulk fuel supplies
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service4} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Advisory Team for Health, Safety And Environment (HSE)
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service5} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Supply of Personal Protective Equipment (PPE)
              </h5>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: '6%',
          }}
        >
          <Col md={4} sm={12}>
            <Row>
              <img src={service11} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Purchase and Delivery of Camping Item
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service14} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Logistics Supply and Planning Management
              </h5>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Row>
              <img src={service118} alt="containerPic" />
            </Row>
            <Row>
              <h5
                style={{
                  fontWeight: 'bold',
                }}
              >
                Fuel Procurement And Delivery For Project Sites
              </h5>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row
          className="viewabcd"
          style={{
            minHeight: '275px',
            backgroundAttachment: 'fixed',
            backgroundImage: `url(${Pic29})`,
            backgroundPosition: '-400px -300px',
            fontStyle: 'italic',
            textAlign: 'center',
          }}
        >
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <AccountCircle
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Emploies in Team</p>
          </Col>
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <LocalShippingIcon
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Company Vihicles</p>
          </Col>
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <LanguageIcon
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Worldwide Clients</p>
          </Col>
          <Col md={3} sm={12} style={{ background: 'rgba(0,0,0,0.5)' }}>
            <CheckCircleIcon
              style={{ marginTop: '10%', color: 'white', fontSize: '75px' }}
            />
            <h1 style={{ color: 'white' }}>250</h1>
            <p style={{ color: '#34CCFF' }}> Projects Done</p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={6} sm={12} style={{ marginBottom: '5%', marginTop: '5%' }}>
            <h3
              style={{
                fontWeight: 'bold',
                marginBottom: '5%',
              }}
            >
              OUR OFFICE
            </h3>
            <img src={Pic30} alt="locations" />
            <p
              style={{
                marginTop: '5%',
                marginBottom: '5%',
              }}
            >
              We can transport your goods around the world. We have offices in
              almost every corner of the earth. Call us for your need today.
            </p>
          </Col>
          <Col md={6} sm={12} style={{ marginBottom: '5%', marginTop: '5%' }}>
            <Row>
              <div
                class="modal-content"
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',

                  left: '5%',
                  top: '5%',
                }}
              >
                <div
                  style={{
                    justifyContent: 'center',
                    fontSize: '12px',
                  }}
                >
                  <div
                    style={{
                      justifyContent: 'center',
                      textAlign: 'center',
                      color: '#777777',
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: '18px',
                          marginTop: '5%',
                        }}
                      >
                        <b>Give us your comment here!.</b>
                      </p>
                    </div>
                  </div>
                </div>

                <Col
                  md={12}
                  sm={12}
                  style={{
                    marginBottom: '5%',
                    marginTop: '5%',
                    marginLeft: '5%',
                  }}
                >
                  <form
                    style={{
                      width: '70%',
                      height: '50%',
                      loat: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <div class="col-xs-4">
                      <input
                        id="userInput"
                        name="appleId"
                        value={total.appleId}
                        className="userInput"
                        placeholder="your Email Address"
                        class="form-control"
                        type="text"
                        onChange={handleChange}
                      ></input>
                    </div>
                    <br></br>
                    <div class="col-xs-4">
                      <textarea
                        id="passwordInput"
                        name="password"
                        value={total.password}
                        className="passwordInput"
                        placeholder="Comment"
                        class="form-control"
                        type="password"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <button
                      style={{
                        fontFamily: 'Arial',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        height: '40px',
                        marginTop: '10px',
                        marginLeft: '85%',
                      }}
                      type="submit"
                      class="btn btn-primary"
                      onClick={(e) => {
                        const onSubmit = (e) => {
                          e.preventDefault();
                          send(
                            'service_19hs2gbb',
                            'template_5fxxqba',
                            total,
                            'W_Q7e7GiR0fArCVML'
                          )
                            .then((response) => {
                              console.log(
                                'SUCCESS!',
                                response.status,
                                response.text
                              );
                              //window.location.href = '/Verifay'
                            })
                            .catch((err) => {
                              console.log('FAILED...', err);
                            });
                        };
                        onSubmit(e);
                      }}
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Submit
                    </button>
                  </form>
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Submitted
                          </h5>
                        </div>
                        <div class="modal-body">
                          Thank you for your comment!
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <div></div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default Services;
