import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from '@material-ui/core/Container';
import Button from 'react-bootstrap/Button';
import footerPic from '../img/footerPic.jpg';
import footerWorld from '../img/footerWorld.jpg';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TimerIcon from '@material-ui/icons/Timer';

const UseStyles = makeStyles({
  flexGrow: {
    flex: '1',
  },
  button: {
    backgroundColor: '#c22d0d',
    '&:hover': {
      backgroundColor: '#1FB5C7',
      color: 'white',
    },
  },
});

function footer() {
  const classes = UseStyles();
  return (
    <div>
      <div
        style={{
          height: '200px',
          backgroundImage: `url(${footerPic})`,
          backgroundPosition: '-550px 0px',
        }}
      >
        <Container float>
          <Row>
            <Col style={{ marginTop: '2%' }}>
              <h2 style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                KAL Logistics
              </h2>
              <h4 style={{ color: 'white', fontStyle: 'italic' }}>
                “ Our support your success ”
              </h4>

              <Link to="/Contact">
                <Button
                  className={classes.button}
                  size="lg"
                  style={{ marginTop: '1%', marginBottom: '2%' }}
                  variant="warning"
                >
                  CONTACT US
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ color: '#62666A', backgroundImage: `url(${footerWorld})` }}>
        <Container float>
          <Row>
            <Col
              xs={12}
              md={4}
              style={{ textAlign: 'center', marginTop: '5%' }}
            >
              <h4
                style={{
                  fontStyle: 'italic',
                  fontFamily: 'bold',
                  color: 'white',
                  marginBottom: '10%',
                }}
              >
                About Kal Loistics
              </h4>
              <p>
                In-fact  Kal Logistic & trading  is a very young company, it is
                established by professionals having more than 10 to 15 years of
                experience in the management of catering ,overall life support ,
                camp and safari establishment , planning and operating fleet and
                camp construction etc, specially for oil , gas  exploration
                ,gold mining and construction  industry .{' '}
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{ textAlign: 'center', marginTop: '5%' }}
            >
              <h4
                style={{
                  fontStyle: 'italic',
                  fontFamily: 'bold',
                  color: 'white',
                  marginBottom: '10%',
                }}
              >
                Quick Links
              </h4>
              <div>
                <Link to="/" style={{ color: '#62666A' }} href="/">
                  Home
                </Link>{' '}
              </div>
              <div>
                <Link to="/AboutUs" style={{ color: '#62666A' }} href="/">
                  About Us
                </Link>{' '}
              </div>
              <div>
                <Link to="/Services" style={{ color: '#62666A' }} href="/">
                  Our Services
                </Link>{' '}
              </div>
              <div>
                <Link to="/Gallery" style={{ color: '#62666A' }} href="/">
                  Gallery
                </Link>{' '}
              </div>
              <div>
                <Link to="/Contact" style={{ color: '#62666A' }} href="/">
                  Contact Us
                </Link>
              </div>
            </Col>
            <Col xs={12} md={4} style={{ textAlign: 'left', marginTop: '5%' }}>
              <h4
                style={{
                  fontStyle: 'italic',
                  fontFamily: 'bold',
                  color: 'white',
                  marginBottom: '10%',
                }}
              >
                Reach Us
              </h4>
              <div style={{ color: '#62666A' }}>
                <LocationOnIcon /> KAL Logistics & trading, Addis ababa,
                Ethiopia
                <br />
                <div>22 mazorya LOTUS Building 2rd Floor /202</div>
              </div>
              <br></br>
              <div style={{ color: '#62666A' }} href="/">
                <PhoneIcon /> +251- 91 138 1262
              </div>{' '}
              <br></br>
              <div style={{ color: '#62666A' }} href="/">
                <MailOutlineIcon /> tsegaye.kalogistic@gmail.com
              </div>{' '}
              <br></br>
              <div style={{ color: '#62666A' }} href="/">
                <TimerIcon /> Monday - Friday : 8.00 - 19.00
              </div>{' '}
            </Col>
          </Row>
          <Row style={{ marginTop: '10%' }}>
            <Col xs={6} md={6} style={{}}>
              Copyright ©
            </Col>
            <Col xs={6} md={6} style={{ textAlign: 'right', marginTop: '3%' }}>
              Powered by Deju
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default footer;
